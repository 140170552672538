<template>
  <div class="Login">
    <form  @submit.prevent.stop="login" class="Wrapper" >
      <h1>Insira o seu e-mail como empresa.</h1>
      <p v-if="error.hasError">{{error.message}}</p>
      <input type="text" placeholder="Nome do Utilizador"  v-model="form.email"/>
      <input type="password" placeholder="Senha" v-model="form.passworld" />
      <router-link class="router-link" to="/RecoveryPasswordPartner">Esqueceu-se da sua senha?</router-link>
      <button ><span class="Next">Seguinte</span></button>
      <router-link class="create-count" to="/formempresa">
          <span>Criar conta</span>
      </router-link>
    </form>
  </div>
</template>
<script>
export default {
  name: 'LoginComponent',
  data () {
    return {
      form: {
        email: '',
        passworld: '',
        role: ''
      },
      user: {
        token: ''
      },
      error: {
        message: null,
        hasError: false
      }
    }
  },
  created () {
    document.title = 'Rise | Entrar'
  },
  methods: {
    login () {
      this.$store.dispatch('LOGINPARCEIRO', {
        email: this.form.email,
        password: this.form.passworld,
        role: 1
      })
        .then(sucess => {
          // this.$router.push('/')
          if (sucess === false) {
            this.error.message = 'Login inválido'
          }
        })
        .catch((e) => {
          this.error.hasError = true
          if (e.response) {
            this.error.message = e.response.data.message
          } else {
            this.error.message = 'Não foi possível estabelecer conexão, tente mais tarde!'
          }
        }).finally(() => {
          setTimeout(() => {
            this.error.hasError = false
          }, 3000)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.Login {
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #26A0A5;
  @media(max-width:560px){
    padding:0 5%!important;
    .create-count{
      margin-top: 25px!important;
    }
  }
  a{
    text-decoration: none!important;
  }
  .Wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
     @media(max-width:560px){
         align-items: center;
    }
    h1 {
      color: #ffffff;
      padding: 20px;
      font-family: "NexaLight", sans-serif;
      font-size: 25px;
      font-weight: lighter;
      opacity: 1;
      letter-spacing: 0;
    }
    input::placeholder, .router-link {
      color: #ffffff;
      opacity: 0.43;
    }
    input {
      background: #20888B;
      padding: 30px 40px;
      border-radius: 6px;
      border: none;
      margin: 10px 0;
      width: 450px;
      color: #fff;
      font-size: 18px;
      font-family: "NexaLight", sans-serif;
      @media(max-width:560px){
        padding: 30px 20px!important;
        width: 100%!important;
      }
    }
    p {
      color: #ffffff;
      padding: 20px;
      font-family: "NexaLight", sans-serif;
      font-size: 18px;
      text-align: right;
      opacity: 0.5;
      cursor: pointer;
    }
    button {
      background: #41B3B6;
      border-radius: 6px;
      padding: 30px 40px;
      border: none;
      margin: 10px 0;
      width: 450px;
      cursor: pointer;
      font-family: "NexaLight", sans-serif;
        @media(max-width:560px){
        width: 100%!important;
      }
    }
    span {
      color: #fdb00b;
      padding: 20px;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      opacity: 0.9;
      cursor: pointer;
    }
    .Next {
      font-size: 18px;
      color: #fff;
      opacity: 0.9;
      letter-spacing: 0;
      font-family: "NexaLight", sans-serif;
    }
  }
  .toobar-container{
    width: 100%;
    height: auto;
    padding: 0 10%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    @media(max-width:560px){
      padding: 0!important;
    }
  }
}
</style>
